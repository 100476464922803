import jQuery from "jquery";
import 'slick-carousel';

const $ = jQuery;


//scroll effect
const $win = $(window);

function scrolloff(event) {
    event.preventDefault();
}

$win.on('load', function () {

    $('.mv .slider').slick({
        // fade: true,
        arrows: false,
        dots: true,
        autoplay: true,
    });
});

$win.on('load resize', function () {
    const wh = $win.height();
    const ww = window.innerWidth;
    const header_trigger = $('.mv').height();
    const header_height = $('.header').height();

    $win.scroll(function () {
        let scroll = $(this).scrollTop();

        $('.act').each(function () {
            let offset = $(this).offset().top;
            let trigger = offset - wh + 100;
            if (scroll > trigger) {
                $(this).addClass('active');
            }
        });
        if (ww > 768) {
            if (scroll >= (header_trigger - header_height)) {
                $('.header_index').addClass('active');
            } else {
                $('.header_index').removeClass('active');
            }
        }
    });
});

// $win.on('load resize', function () {
//     const wh = $win.height();
//     const mission_offset = $('.trigger_mission').offset().top;
//     const vision_offset = $('.trigger_vison').offset().top;
//     const value_offset = $('.trigger_value').offset().top;
//
//     $win.scroll(function () {
//         let scroll = $(this).scrollTop();
//         $('.sticky_area').each(function () {
//             if (scroll >= mission_offset - wh && scroll < vision_offset - wh) {
//                 $('.sticky_area').removeClass('active');
//                 $('.mission').addClass('active');
//             }
//             if (scroll >= vision_offset - wh && scroll < value_offset - wh) {
//                 $('.sticky_area').removeClass('active');
//                 $('.vision').addClass('active');
//             }
//             if (scroll >= value_offset - wh) {
//                 $('.sticky_area').removeClass('active');
//                 $('.value').addClass('active');
//             }
//         });
//     });
// });

//drawer menu
const drawer = $('body');

$('.buns').on("click", function () {
    drawer.toggleClass('drawer-open');
    $(this).toggleClass('active');
    $('.nav').toggleClass('active');
    // $('.overlay').toggleClass('active');
    if (drawer.hasClass('drawer-open')) {
        //scroll禁止
        $('html, body').css('overflow', 'hidden');
        //scroll禁止(ios)
        document.addEventListener('touchmove', scrolloff, {passive: false});
    } else {
        $('html, body').removeAttr('style');
        document.removeEventListener('touchmove', scrolloff, false);
    }
});

//外部リンクにtarget blank付与
$(document).on("click",
    "a[href^=http]:not([target]):not([href*='" + location.hostname + "'])",
    function (event) {
        $(event.currentTarget).attr("target", "_blank")
    }
);

// $(function () {
//     $('a[href^="#"]').click(function () {
//         let hash = location.hash;
//         let position;
//         const speed = 500;
//         var href = $(this).attr("href");
//         const header = $('.header').innerHeight();
//         var target = $(href == "#" || href == "" ? 'html' : href);
//         if (hash === '#mission') {
//             let position = $('.mission').offset().top - header;
//         } else {
//             let position = target.offset().top - header;
//         }
//         $("html, body").animate({scrollTop: position}, speed, "swing");
//         return false;
//     });
// });

$(function () {
    let headerHeight = $('.header').outerHeight();
    let urlHash = location.hash;
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            var target = $(urlHash);
            var position = target.offset().top;
            $('body,html').stop().animate({scrollTop: position - headerHeight}, 500);
        }, 100);
    }
    $('a[href^="#"]').click(function () {
        let href = $(this).attr("href");
        let target = $(href);
        let position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop: position}, 500);
    });
});
